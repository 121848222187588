import React from 'react'
import { navigate } from 'gatsby'
import { Breadcrumb, Layout } from 'antd'
import HeaderLogo from '../images/header-logo.png'
import { Helmet } from 'react-helmet'
const { Header, Content, Footer } = Layout
export default function PublicPrivacyPolicy() {
  return (
    <Layout>
      <Helmet title="Privacy Policy | SB Express" defer={false} />
      <Header
        style={{
          position: 'sticky',
          top: 0,
          zIndex: 1,
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          background: 'white',
          height: '100%',
        }}
      >
        <img src={HeaderLogo} alt="" className="login-header-image" style={{ height: '80px', padding: '10px 0' }} />
      </Header>
      <Content
        className="site-layout"
        style={{
          padding: '0 50px',
        }}
      >
        <div
          style={{
            padding: 24,
            minHeight: 380,
          }}
        >
          <Breadcrumb
            style={{
              margin: '16px 0',
              paddingBottom: 30,
            }}
          >
            <Breadcrumb.Item onClick={() => navigate('/')}>Home</Breadcrumb.Item>
            <Breadcrumb.Item>Privacy Policy</Breadcrumb.Item>
          </Breadcrumb>
          <div class="w-richtext">
            <h4>Effective date: 11/28/2022</h4>
            <p>
              S.B Express Inc (“S.B Express Inc”) is committed to maintaining robust privacy protections for its users. Our Privacy Policy (“Privacy
              Policy”) is designed to help you understand how we collect, use, and safeguard the information you provide to us and to assist you in
              making informed decisions when using our Service.
            </p>
            <p>For the purposes of this Agreement, “Site” refers to the Company’s website, which can be accessed at [http://mysbexpress.com].</p>
            <p>
              “Service” refers to the Company’s services accessed via the Site, and mobile app in which users can use the various software services
              provided by each system or software product.
            </p>
            <p>The terms “we,” “us,” and “our” refer to S.B Express Inc.</p>
            <p>“You” refers to you as a user of our Site or our Service.</p>
            <p>
              By accessing our Site or our Service, you accept our Privacy Policy and Terms of Use and consent to our collection, storage, use, and
              disclosure of your Personal Information as described in this Privacy Policy.
            </p>
            <p>
              <br />
            </p>
            <h4>
              <strong>I. INFORMATION WE COLLECT</strong>
            </h4>
            <p>
              We collect “Non-Personal Information” and “Personal Information.” Non-Personal Information includes information that cannot be used to
              identify you, such as anonymous usage data personally, general demographic information we may collect, referring/exit pages and URLs,
              platform types, preferences you submit, and preferences that are generated based on the data you submit and the number of clicks.
              Personal Information includes your email address [first name, last name, username, date of birth, gender, phone number, emergency
              contact number, state identification number, telephone number, fax number, credit/debit card number(s), and home (postal) address] which
              you submit to us through the registration process at the Site.
            </p>
            <p>
              <br />
            </p>
            <h5>
              <strong>1. Information collected via Technology</strong>
            </h5>
            <p>
              To activate the Service, you do not need to submit any Personal Information other than your email address. To use the Service
              thereafter, you [do/do not] need to submit further Personal Information [, which may include a list of Personal Information collected].
              However, in an effort to improve the quality of the Service, we track information provided to us by your browser or by our software
              application when you view or use the Service, such as the website you came from (known as the “referring URL”), the type of browser you
              use, the device from which you connected to the Service, the time and date of access, and other information that does not personally
              identify you. We track this information using cookies or small text files, which include a unique anonymous identifier. Cookies are sent
              to a user’s browser from our servers and are stored on the user’s computer hard drive. Sending a cookie to a user’s browser enables us
              to collect non-personal information about that user and keep a record of the user’s preferences when utilizing our services, both on an
              individual and aggregate basis. For example, the Company may use cookies to collect the following information:
            </p>
            <ul>
              <li>
                Identifiers: contact information including your first and last name, email address, username, phone number, home (postal) address,
                state identification number, driver’s license number, telephone number, fax number, credit/debit card number(s).
              </li>
              <li> Professional or employment-related information: your company, job title, and employment status.</li>
              <li>
                Internet or other electronic network activity information, including analytical data, e.g., pages and products viewed, ads clicked on,
                emails from us that you opened, browser, operating system ("OS"), Internet Protocol ("IP") address, and device information, and any
                data collected by cookies. (See Sample Policies for cookies-related language if in use) When using apps, we often gather information
                about your mobile device, referring/exit pages and URLs, domain names, landing pages, and other information. When you access our
                Services by or through a mobile device, we may receive or collect and store a unique identification number associated with your
                device, mobile carrier, device type, manufacturer, phone number, and, depending on your mobile device settings, your geographical
                location data, including GPS coordinates or similar information regarding the location of your mobile device.
              </li>
              <li>
                Protected personal information, including age, ethnicity, religion/creed, marital status, physical illness, disabilities, gender, and
                veteran status.
              </li>
            </ul>
            <p>
              The Company may use both persistent and session cookies; persistent cookies remain on your computer after you close your session and
              until you delete them, while session cookies expire when you close your browser. [For example, we store a persistent cookie to track
              unique identifiers to keep track of your login session.
            </p>
            <p>
              <br />
            </p>
            <h5>
              <strong>2. Information you provide us by registering for an account</strong>
            </h5>
            <p>
              In addition to the information provided automatically by your browser when you visit the Site, to become a subscriber to the Service,
              you will need to create a personal profile. You can create a profile by registering with the Service, entering your email address, and
              creating a username and password. By registering, you authorize us to collect, store and use your email address per this Privacy Policy.
            </p>
            <p>
              <br />
            </p>
            <h5>
              <strong>3. Children’s Privacy</strong>
            </h5>
            <p>
              The Site and the Service are not directed to anyone under 13. The Site does not knowingly collect or solicit information from anyone
              under the age of 13 or allow anyone under the age of 13 to sign up for the Service. In the event that we learn that we have gathered
              personal information from anyone under the age of 13 without the consent of a parent or guardian, we will delete that information as
              soon as possible.
            </p>
            <p>
              <br />
            </p>
            <h4>
              <strong>II. HOW WE USE AND SHARE INFORMATION</strong>
            </h4>
            <p> </p>
            <h5>
              <strong>Personal Information:</strong>
            </h5>
            <p></p>
            <p>
              Except as otherwise stated in this Privacy Policy, we do not sell, trade, rent, or otherwise share for marketing purposes your Personal
              Information with third parties without your consent. We do share Personal Information with vendors who are performing services for the
              Company, such as the servers for our email communications, which are provided access to the user’s email address for purposes of sending
              emails from us. Those vendors use your Personal Information only at our direction and in accordance with our Privacy Policy.
            </p>
            <p>
              In general, the Personal Information you provide us is used to help us communicate with you. For example, we use Personal Information to
              contact users in response to questions, solicit feedback from users, provide technical support, and inform users about promotional
              offers.
            </p>
            <p>
              We may share Personal Information with outside parties if we have a good-faith belief that access, use, preservation, or disclosure of
              the information is reasonably necessary to meet any applicable legal process or enforceable governmental request; to enforce applicable
              Terms of Service, including investigation of potential violations; address fraud, security or technical concerns; or to protect against
              harm to the rights, property, or safety of our users or the public as required or permitted by law.
            </p>
            <p> </p>
            <h5>
              <strong>Non-Personal Information</strong>
            </h5>
            <p></p>
            <p>
              In general, we use Non-Personal Information to help improve the Service and customize the user experience. We also aggregate
              Non-Personal Information in order to track trends and analyze use patterns on the Site. This Privacy Policy does not limit our use or
              disclosure of Non-Personal Information; we reserve the right to use and disclose such Non-Personal Information to our partners,
              advertisers, and other third parties at our discretion.
            </p>
            <p>
              In the event we undergo a business transaction such as a merger, acquisition by another company, or sale of all or a portion of our
              assets, your Personal Information may be among the assets transferred. You acknowledge and consent that such transfers may occur and are
              permitted by this Privacy Policy and that any acquirer of our assets may continue to process your Personal Information as outlined in
              this Privacy Policy. If our information practices change at any time in the future, we will post the policy changes to the Site so that
              you may opt-out of the new information practices. We suggest you check the Site periodically if you are concerned about how your
              information is used.
            </p>
            <p>
              <br />
            </p>
            <h4>III. HOW WE PROTECT INFORMATION</h4>
            <p>
              We implement security measures designed to protect your information from unauthorized access. Your account is protected by your account
              password, and we urge you to take steps to keep your personal information safe by not disclosing your password and by logging out of
              your account after each use. We further protect your information from potential security breaches by implementing certain technological
              security measures, including encryption, firewalls, and secure socket layer technology. However, these measures do not guarantee that
              your information will not be accessed, disclosed, altered, or destroyed by a breach of such firewalls and secure server software. Using
              our Service, you acknowledge that you understand and agree to assume these risks.
            </p>
            <p>
              <br />
            </p>
            <h4>IV. YOUR RIGHTS REGARDING THE USE OF YOUR PERSONAL INFORMATION</h4>
            <p>
              You have the right at any time to prevent us from contacting you for marketing purposes. When we send promotional communication to a
              user, the user can opt out of further promotional communications by following the unsubscribe instructions provided in each promotional
              e-mail. You can also indicate that you do not wish to receive marketing communications from us in the [list location of opt-out page,
              i.e., “Settings” section] of the Site. Please note that notwithstanding the promotional preferences you indicate by either unsubscribing
              or opting out in the [location of opt-out page] of the Site, we may continue to send you administrative emails, including, for example,
              periodic updates to our Privacy Policy.
            </p>
            <p>
              <br />
            </p>
            <h4>V. LINKS TO OTHER WEBSITES</h4>
            <p>
              As part of the Service, we may provide links to or compatibility with other websites or applications. However, we are not responsible
              for the privacy practices employed by those websites or the information or content they contain. This Privacy Policy applies solely to
              information collected by us through the Site and the Service. Therefore, this Privacy Policy does not apply to your use of a third-party
              website accessed by selecting a link on our Site or via our Service. To the extent that you access or use the Service through or on
              another website or application, then the privacy policy of that other website or application will apply to your access or use of that
              site or application. We encourage our users to read the privacy statements of other websites before proceeding to use them.
            </p>
            <p>
              <br />
            </p>
            <h4>VI. CHANGES TO OUR PRIVACY POLICY</h4>
            <p>
              The Company reserves the right to change this policy and our Terms of Service at any time. We will notify you of significant changes to
              our Privacy Policy by sending a notice to the primary email address specified in your account or by placing a prominent notice on our
              site. Significant changes will go into effect 30 days following such notification. Non-material changes or clarifications will take
              effect immediately. You should periodically check the Site and this privacy page for updates.
            </p>
            <p>
              <br />
            </p>
            <h4>VII. CONTACT US</h4>
            <p>If you have any questions regarding this Privacy Policy or the practices of this Site, please contact us:</p>
            <ul>
              <li>
                By email at <a href="mailto:info@sbexpress.com">info@sbexpress.com</a>
              </li>
              <li> By telephone at: 1-224-220-0200</li>
              <li> By US mail at: “S.B Express Inc” 1451 Elmhurst Rd, Elk Grove Village IL </li>
            </ul>
            <p>
              <br />
            </p>
            <p>
              <strong> Last Updated: This Privacy Policy was last updated on [11/28/2022].</strong>
            </p>

            <p>
              <br />
            </p>
            <p>
              If you have difficulty accessing any material provided through this Privacy Policy because of a disability, please contact us in writing
              or by telephone and we will work with you to make the information available.
            </p>
          </div>
        </div>
      </Content>
      <Footer
        style={{
          textAlign: 'center',
        }}
      >
        ©2023 S.B.Express inc
      </Footer>
    </Layout>
  )
}
